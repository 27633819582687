









import { StepData } from '@/src/components/processes/rs-wizard.vue';
import Vue from 'vue';
// import LinkMembershipRequestViewModel from '../../../../../services/generated/types/model/request/LinkMembershipRequestModel';
import LinkMembershipRequestViewModel from '../../../../../services/viewModel/request/Memberships/LinkMembershipRequestViewModel';
import twoSchema from './schema/2.schema';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    title: 'page.linkMembership.page.title',
    model: LinkMembershipRequestViewModel,
    steps: [
      {
        breadcrumb: 'page.linkMembership.label.stepTitles.1',
        component: () => import('./linkMembership1.vue'),
        sendData: false,
      },
      {
        breadcrumb: 'page.linkMembership.label.stepTitles.2',
        component: () => import('./linkMembership2.vue'),
        sendData: true,
        clearProgressAfterSend: true,
        schema: twoSchema,
      },
      {
        breadcrumb: 'page.linkMembership.label.stepTitles.3',
        component: () => import('./linkMembership3.vue'),
      },
    ] as StepData[],
  }),
  methods: {
    async serviceFunction(wizardData: LinkMembershipRequestViewModel) {
      await this.$service.api.memberships.linkMembership(wizardData);
      await this.$auth.refresh();
    },
  },
});
