// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import LinkMembershipRequestDTO from '../../dto/request/LinkMembershipRequestDTO';

export default abstract class LinkMembershipRequestModel extends BaseModel<LinkMembershipRequestDTO> {
  // #region    ──BodyRequest──────────────────────────╮
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  */
  get verificationCode() { return this.dto.verificationCode; }
  set verificationCode(value) { this.dto.verificationCode = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.verificationCode = queries.verificationCode;
  }
}
